<template>
  <a-modal
      centered
      :width='700'
      :title="title"
      v-model="showModal"
      @ok='handleConfirm'
      @cancel='handleCancel'
  >
    <div>
      <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
        <a-form-model-item label='用户Id' prop='content'>
          <a-input placeholder='请输入用户Id' v-model="forms.userId"></a-input>
        </a-form-model-item>
        <a-form-model-item label='已选择' prop='content'>
          <template  v-for="item in selectedCoinList">
            <a-tag
                color="blue"
                closable
                :key="item.coinKindId"
                @close="handleDeleteTag($event, item)"
            >{{ item.coinKindName }}</a-tag>
          </template>
          <a-tag
              v-if="!isShowBigCoinByOnlyListStatus"
              class="cur-pot"
              color="red"
              @click="handleAddTag($event)"
          ><a-icon type="plus" />单独新增大币种</a-tag>
          <a-select
              v-if="isShowBigCoinByOnlyListStatus"
              @search="searchData"
              v-model="coinIds"
              :filter-option="untils.filterOption"
              showSearch
              class="w-200 mt-5"
              mode="multiple"
              placeholder="请搜索选择大币种"
          >
            <a-select-option
                v-for="(item, index) of bigListByOnlyList"
                :key="index"
                :value="item.id"
            >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='搜索币种朝代' prop='degree'>
          <a-select
              @search="searchDynasty"
              v-model="dynastyId"
              :filter-option="untils.filterOption"
              showSearch
              placeholder="请选择"
              @change="handleChangeDynasty"
          >
            <a-select-option
                v-for="(item, index) of dynastyList"
                :key="index"
                :value="item.id"
            >{{ item.dynastyName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='选择大币种' prop='degree' v-if="dynastyId">
          <a-select
              v-model="coinIds"
              :filter-option="untils.filterOption"
              showSearch
              mode="multiple"
              placeholder="请选择"
              @change="handleChangeBigCoin"
          >
            <a-select-option
                v-for="(item, index) of bigList"
                :key="index"
                :value="item.id"
            >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import untils from "@/untils"
import {
  getDynastyBigCoinAbout,
  getDynastyList,
  postAddRatingAuth,
  postEditorRatingAuth
} from "@/views/cmsPage/versionManage/versionAuthAbout/_apis"
import {getBigCoinList} from "@/axios/apis"
export default {
  data() {
    return {
      untils,
      title: '添加',
      showModal: false,
      bigList: [],
      bigListByOnlyList: [],
      forms: {
        id: undefined,
        coinIds: undefined,
        userId: undefined
      },
      coinIds: undefined,
      dynastyId: undefined,
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      rules: {
        userId:[{ required: true, message: '用户ID不能为空', trigger: 'blur' }],
        coinIds:[{ required: true, message: '大币种不能为空', trigger: 'blur' }],
      },
      ratingVersionId: '',
      selectedCoinList: [],
      dynastyList: [],
      isLoading: false,
      isShowBigCoinByOnlyListStatus: false
    }
  },
  methods: {
    /** 单独新增大币种 */
    handleAddTag() {
      console.log('zengjia')
      this.isShowBigCoinByOnlyListStatus = true
    },
    /** 改变大币种 */
    handleChangeBigCoin(e) {

    },
    show(data) {
      if (data) {
        this.ratingVersionId = data.userId
        this.forms.userId = data.userId
        this.selectedCoinList = JSON.parse(JSON.stringify(data.coinKindList))
        this.title = '编辑'
      }
      this.showModal = true
    },
    /** 删除已经选择的 */
    handleDeleteTag(e, item) {
      e.preventDefault();
      const foundIndex = this.selectedCoinList.findIndex(el => el.coinKindId === item.coinKindId)
      this.selectedCoinList.splice(foundIndex, 1)
    },
    /** 确定 */
    async handleConfirm() {
      if (this.isLoading) return this.$message.warn('请勿重复点击')
      this.isLoading = true
      this.$loading.show()
      if (!this.forms.userId) return this.$message.warn('用户ID不能为空')
      if (!this.coinIds && this.selectedCoinList.length < 1) return this.$message.warn('大币种不能为空')
      if (this.ratingVersionId) {
        const tempArr = this.selectedCoinList.map(el => el.coinKindId) || []
        const tempArrCoin = this.coinIds || []
        this.forms.coinIds = tempArrCoin.concat(tempArr).join(',')
      } else {
        this.forms.coinIds = this.coinIds.join(',')
      }
      let res
      if (this.ratingVersionId) {
        res = await postEditorRatingAuth(this.forms)
      } else {
        res = await postAddRatingAuth(this.forms)
      }
      this.$loading.hide()
      this.isLoading = false
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.$emit('addOrEditorSuccess')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.resetForms()
      this.showModal = false
    },
    /** 重置表单 */
    resetForms() {
      this.forms = {
        coinIds: undefined,
        userId: undefined
      }
      this.dynastyList = []
      this.dynastyId = undefined
      this.bigList = []
      this.bigListByOnlyList = []
      this.coinIds = undefined
      this.isShowBigCoinByOnlyListStatus = false
    },
    /** 搜索朝代 */
    async searchDynasty(inner) {
      const res = await getDynastyList({dynastyName: inner})
      if (res.status !== '200') return
      this.dynastyList = res.data
    },
    /** 改变朝代 */
    handleChangeDynasty(e) {
      this.searchBigCoins()
    },
    /** 根据朝代选择大币种 */
    async searchBigCoins() {
      const res = await getDynastyBigCoinAbout({dynastyId: this.dynastyId})
      if (res.status !== '200') return
      this.bigList = res.data
      this.coinIds = this.bigList.map(el => el.id)
    },
    /** 搜索大币种 */
    async searchData(inner) {
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      const { records } = res.data;
      this.bigListByOnlyList = records;
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .ant-form-item-control {
  line-height: 30px !important;
}

</style>